<!--
 * @Author: zhangjingqing
 * @Date: 2022-04-25 21:50:22
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-28 16:31:23
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\tenantManagement\tenant\FieldsModal.vue
-->
<template>
  <a-modal
    title="已关联扩展字段"
    :width="800"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @cancel="handleCancel"
    @ok="handleSave"
  >
    <div class="options">
      <a-button type="primary" icon="plus" @click="showFieldsList"
        >关联扩展字段</a-button
      >
    </div>
    <a-table
      rowKey="id"
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :row-selection="rowSelection"
    >
      <span slot="expandFieldId" slot-scope="text, record">
        {{ formatName(record.expandFieldId) }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)">编辑</a>
        <a-divider type="vertical"></a-divider>
        <a @click="handleDelete(record.expandFieldId)">删除</a>
      </span>
    </a-table>
  </a-modal>
</template>
<script>
// import pick from "lodash.pick";
import {
  queryExtendFieldServiceList,
  querySystemTenantidExtendList,
  deleteSystemTenantidExtend,
} from "@/api/tenant";
const columns = [
  {
    title: "扩展字段名称",
    dataIndex: "expandFieldId",
    key: "expandFieldId",
    scopedSlots: { customRender: "expandFieldId" },
  },
  {
    title: "扩展字段值",
    dataIndex: "expandFieldValue",
    key: "expandFieldValue",
  },
  {
    title: "租户id",
    dataIndex: "tenantId",
    key: "tenantId",
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "menuModal",
  data() {
    return {
      visible: false,
      tenantId: "",
      form: this.$form.createForm(this, { name: "form" }),
      confirmLoading: false,
      data: [],
      dict: [],
      columns,
      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  methods: {
    formatName(val) {
      return this.dict.filter((item) => item.id == val)[0].expandFieldName;
    },
    async queryDict() {
      this.loading = true;
      let res = await queryExtendFieldServiceList({
        page: 1,
        pageSize: 100,
      });
      if (res.status == 200) {
        this.dict = res.data.rows;
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
      this.loading = false;
    },
    async queryList() {
      this.loading = true;
      let res = await querySystemTenantidExtendList({
        tenantId: this.tenantId,
        page: 1,
        pageSize: 100,
      });
      if (res.status == 200) {
        this.data = res.data.rows;
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
      this.loading = false;
    },
    async show(tenantId) {
      this.tenantId = tenantId;
      await this.queryDict();
      await this.queryList();
      this.visible = true;
    },
    showFieldsList() {
      this.$emit("showFieldsList", this.tenantId, 1);
    },
    handleEdit(record) {
      this.$emit("showFieldsList", this.tenantId, 2, record);
    },
    async handleDelete(id) {
      const that = this;
      this.$confirm({
        title: "确认删除",
        content: "是否删除当前数据?",
        onOk: async function () {
          let params = {
            expandFieldId: id,
            tenantId: that.tenantId,
          };
          let res = await deleteSystemTenantidExtend(params);
          if (res.status == 200) {
            that.queryList();
          } else {
            that.$notification.error({
              message: "系统提示",
              description: res.msg || res.message,
              duration: 4,
            });
          }
        },
      });
    },
    handleSave() {
      this.form.resetFields();
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.visible = false;
    },
    handleCancel() {
      this.form.resetFields();
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.visible = false;
    },
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          this.selectedRows = selectedRows;
        },
      };
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-table-body {
  max-height: 569px;
  overflow-y: scroll;
}
.options {
  margin-bottom: 16px;
}
</style>