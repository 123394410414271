var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":_vm.title,"width":800,"visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleSave,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 16 }}},[_c('a-form-item',{attrs:{"label":"租户名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          { rules: [{ required: true, message: '租户名称不能为空' }] },
        ]),expression:"[\n          'name',\n          { rules: [{ required: true, message: '租户名称不能为空' }] },\n        ]"}],attrs:{"placeholder":"请输入租户名称"}})],1),_c('a-form-item',{attrs:{"label":"联系邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'contactEmail',
          { rules: [{ required: true, message: '联系邮箱不能为空' }] },
        ]),expression:"[\n          'contactEmail',\n          { rules: [{ required: true, message: '联系邮箱不能为空' }] },\n        ]"}],attrs:{"placeholder":"请输入联系邮箱"}})],1),(_vm.status == 2)?_c('a-form-item',{attrs:{"label":"管理员密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          { rules: [{ required: true, message: '管理员密码不能为空' }] },
        ]),expression:"[\n          'password',\n          { rules: [{ required: true, message: '管理员密码不能为空' }] },\n        ]"}],attrs:{"placeholder":"请输入管理员密码"}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'contactMobile',
          { rules: [{ required: true, message: '联系电话不能为空' }] },
        ]),expression:"[\n          'contactMobile',\n          { rules: [{ required: true, message: '联系电话不能为空' }] },\n        ]"}],attrs:{"placeholder":"请输入联系电话"}})],1),_c('a-form-item',{attrs:{"label":"联系人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'contactPerson',
          { rules: [{ required: true, message: '联系人不能为空' }] },
        ]),expression:"[\n          'contactPerson',\n          { rules: [{ required: true, message: '联系人不能为空' }] },\n        ]"}],attrs:{"placeholder":"请输入联系人"}})],1),_c('a-form-item',{attrs:{"label":"系统名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'systemName',
          { rules: [{ required: true, message: '系统名称不能为空' }] },
        ]),expression:"[\n          'systemName',\n          { rules: [{ required: true, message: '系统名称不能为空' }] },\n        ]"}],attrs:{"placeholder":"请输入系统名称"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }