<!--
 * @Author: zhangjingqing
 * @Date: 2022-04-09 10:12:10
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-27 17:52:57
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\tenantManagement\tenant\Index.vue
-->
<template>
  <div class="container">
    <div class="options">
      <a-space>
        <a-button type="primary" icon="plus" @click="handleAdd"
          >新增租户</a-button
        >
        <!-- <a-button icon="profile" @click="handleRela">关联菜单</a-button> -->
        <a-button icon="font-size" @click="handleRelaFields"
          >关联扩展字段</a-button
        >
        <a-button icon="setting" @click="handleSet">租户配置</a-button>
      </a-space>
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      :rowSelection="{ onChange: onSelChange }"
      :loading="loading"
      :pagination="{ current: current, pageSize: pageSize, total: total }"
      @change="handleTableChange"
    >
      <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)">编辑</a>
        <a-divider type="vertical" />
        <a @click="handleDelete(record.id)">删除</a>
      </span>
    </a-table>
    <tenant-modal ref="tenantModal" @reload="queryList"></tenant-modal>
    <setting-modal ref="settingModal"></setting-modal>
    <menu-modal ref="menuModal"></menu-modal>
    <fields-modal
      ref="fieldsModal"
      @showFieldsList="showFieldsList"
    ></fields-modal>
    <fields-list-modal
      ref="fieldsListModal"
      @reloadField="reloadField"
    ></fields-list-modal>
  </div>
</template>
<script>
import TenantModal from "./TenantModal";
import SettingModal from "./SettingModal";
import MenuModal from "./MenuModal";
import FieldsModal from "./FieldsModal";
import FieldsListModal from "./FiledsListModal.vue";
import { querySystemTenantidList, deleteSystemTenantid } from "@/api/tenant";
const columns = [
  {
    title: "租户id",
    dataIndex: "id",
    key: "id",
  },
  // {
  //   title: "uuid",
  //   dataIndex: "uuid",
  //   key: "uuid",
  // },
  {
    title: "租户名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "联系邮箱",
    dataIndex: "contactEmail",
    key: "contactEmail",
  },
  {
    title: "联系人",
    dataIndex: "contactPerson",
    key: "contactPerson",
  },
  {
    title: "联系电话",
    dataIndex: "contactMobile",
    key: "contactMobile",
  },
  {
    title: "系统名称",
    dataIndex: "systemName",
    key: "systemName",
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "tenant",
  components: {
    TenantModal,
    SettingModal,
    MenuModal,
    FieldsModal,
    FieldsListModal,
  },
  data() {
    return {
      loading: false,
      data: [],
      columns,
      selectedRowKeys: [],
      selectedRows: [],
      current: 1,
      pageSize: 10,
      total: 0,
    };
  },
  created() {
    this.queryList();
  },
  methods: {
    onSelChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    async queryList() {
      this.loading = true;
      let res = await querySystemTenantidList({
        page: this.current,
        pageSize: this.pageSize,
      });
      if (res.status == 200) {
        this.data = res.data.rows;
        this.total = res.data.records;
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
      this.loading = false;
    },
    handleDelete(id) {
      const that = this;
      this.$confirm({
        title: "确认删除",
        content: "是否删除当前数据?",
        onOk: async function () {
          let res = await deleteSystemTenantid({ id: id });
          if (res.status == 200) {
            that.$message.success("删除成功");
            that.queryList();
          } else {
            that.$notification.error({
              message: "系统提示",
              description: res.msg || res.message,
              duration: 4,
            });
          }
        },
      });
    },
    handleAdd() {
      this.$refs.tenantModal.show(2, {});
    },
    handleEdit(record) {
      this.$refs.tenantModal.show(3, record);
    },
    handleRela() {
      let len = this.selectedRows.length;
      if (len != 1) {
        return this.$message.warning("请选择一条数据！");
      }
      this.$refs.menuModal.show(this.selectedRows[0].id);
    },
    handleRelaFields() {
      let len = this.selectedRows.length;
      if (len != 1) {
        return this.$message.warning("请选择一条数据！");
      }
      this.$refs.fieldsModal.show(this.selectedRows[0].id);
    },
    handleSet() {
      let len = this.selectedRows.length;
      if (len != 1) {
        return this.$message.warning("请选择一条数据！");
      }
      this.$refs.settingModal.show(this.selectedRows[0].id);
    },
    showFieldsList(tenantId, status, record) {
      this.$refs.fieldsListModal.show(tenantId, status, record);
    },
    reloadField() {
      this.$refs.fieldsModal.queryList();
    },
    handleTableChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.total = pagination.total;
      this.queryList();
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  padding: 20px;
  background: #fff;
  min-height: 280px;
}
.options {
  padding-bottom: 16px;
}
</style>
