var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"租户配置","width":800,"visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleSave,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 16 }}},[_c('a-form-item',{attrs:{"label":"电话坐席数量上限"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'agentSize',
          {
            rules: [{ required: true, message: '电话坐席数量上限不能为空' }],
          },
        ]),expression:"[\n          'agentSize',\n          {\n            rules: [{ required: true, message: '电话坐席数量上限不能为空' }],\n          },\n        ]"}],attrs:{"type":"number","placeholder":"请输入电话坐席数量上限"}})],1),_c('a-form-item',{attrs:{"label":"文件存储量上限(kb)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'fileSize',
          {
            rules: [
              { required: true, message: '文件存储量上限(kb)不能为空' },
            ],
          },
        ]),expression:"[\n          'fileSize',\n          {\n            rules: [\n              { required: true, message: '文件存储量上限(kb)不能为空' },\n            ],\n          },\n        ]"}],attrs:{"type":"number","placeholder":"请输入文件存储量上限(kb)"}})],1),_c('a-form-item',{attrs:{"label":"图片存储量上限(kb)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'pictureSize',
          {
            rules: [
              { required: true, message: '图片存储量上限(kb)不能为空' },
            ],
          },
        ]),expression:"[\n          'pictureSize',\n          {\n            rules: [\n              { required: true, message: '图片存储量上限(kb)不能为空' },\n            ],\n          },\n        ]"}],attrs:{"type":"number","placeholder":"请输入图片存储量上限(kb)"}})],1),_c('a-form-item',{attrs:{"label":"用户数量上限"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'userSize',
          { rules: [{ required: true, message: '用户数量上限不能为空' }] },
        ]),expression:"[\n          'userSize',\n          { rules: [{ required: true, message: '用户数量上限不能为空' }] },\n        ]"}],attrs:{"type":"number","placeholder":"请输入用户数量上限"}})],1),_c('a-form-item',{attrs:{"label":"有效结束时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'validityEndTime',
          { rules: [{ required: true, message: '有效结束时间不能为空' }] },
        ]),expression:"[\n          'validityEndTime',\n          { rules: [{ required: true, message: '有效结束时间不能为空' }] },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请输入有效结束时间"}})],1),_c('a-form-item',{attrs:{"label":"有效开始时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'validityStartTime',
          { rules: [{ required: true, message: '有效开始时间不能为空' }] },
        ]),expression:"[\n          'validityStartTime',\n          { rules: [{ required: true, message: '有效开始时间不能为空' }] },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请输入有效开始时间"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }