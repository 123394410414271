<!--
 * @Author: zhangjingqing
 * @Date: 2022-04-09 10:32:31
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-02 17:38:48
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\tenantManagement\tenant\MenuModal.vue
-->
<template>
  <a-modal
    title="关联菜单"
    :width="800"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleSave"
    @cancel="handleCancel"
  >
    <!-- <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="租户名称">
        <a-input
          v-decorator="[
            'menuName',
            { rules: [{ required: true, message: '租户名称不能为空' }] },
          ]"
          placeholder="请输入租户名称"
        />
      </a-form-item>
      <a-form-item label="联系邮箱">
        <a-input
          v-decorator="[
            'menuName',
            { rules: [{ required: true, message: '联系邮箱不能为空' }] },
          ]"
          placeholder="请输入联系邮箱"
        />
      </a-form-item>
      <a-form-item label="联系电话">
        <a-input
          v-decorator="[
            'menuName',
            { rules: [{ required: true, message: '联系电话不能为空' }] },
          ]"
          placeholder="请输入联系电话"
        />
      </a-form-item>
      <a-form-item label="联系人">
        <a-input
          v-decorator="[
            'menuName',
            { rules: [{ required: true, message: '联系人不能为空' }] },
          ]"
          placeholder="请输入联系人"
        />
      </a-form-item>
      <a-form-item label="系统名称">
        <a-input
          v-decorator="[
            'menuName',
            { rules: [{ required: true, message: '系统名称不能为空' }] },
          ]"
          placeholder="请输入系统名称"
        />
      </a-form-item>
    </a-form> -->
    <a-table
      rowKey="id"
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :row-selection="rowSelection"
    />
  </a-modal>
</template>
<script>
// import pick from "lodash.pick";
// import { insertCatalogmenu, updateCatalogmenu } from "@/api/cata";
const columns = [
  {
    title: "菜单权限",
    dataIndex: "name",
    key: "name",
  },
];

const data = [
  {
    id: 3,
    parentId: null,
    name: "首页",
    type: 1,
    selected: true,
  },
  {
    id: 1,
    parentId: null,
    name: "人员管理",
    type: 1,
    selected: true,
    children: [
      {
        id: 2,
        parentId: 1,
        name: "员工管理",
        type: 2,
        selected: true,
      },
      {
        id: 7,
        parentId: 1,
        name: "岗位管理",
        type: 2,
        selected: true,
      },
      {
        id: 8,
        parentId: 1,
        name: "角色管理",
        type: 2,
        selected: true,
      },
    ],
  },
  {
    id: 4,
    parentId: null,
    name: "应用配置管理",
    type: 1,
    selected: true,
    children: [
      {
        id: 9,
        parentId: 4,
        name: "应用管理",
        type: 2,
        selected: true,
      },
      {
        id: 10,
        parentId: 4,
        name: "对象管理",
        type: 2,
        selected: true,
      },
    ],
  },
  {
    id: 5,
    parentId: null,
    name: "规则管理",
    type: 1,
    selected: true,
    children: [
      {
        id: 11,
        parentId: 5,
        name: "触发器管理",
        type: 2,
        selected: true,
      },
      {
        id: 12,
        parentId: 5,
        name: "SLA管理",
        type: 2,
        selected: true,
      },
      {
        id: 13,
        parentId: 5,
        name: "签到管理",
        type: 2,
        selected: true,
      },
      {
        id: 14,
        parentId: 5,
        name: "工作时间管理",
        type: 2,
        selected: true,
      },
      {
        id: 15,
        parentId: 5,
        name: "节假日管理",
        type: 2,
        selected: true,
      },
    ],
  },
  {
    id: 6,
    parentId: null,
    name: "系统管理",
    type: 1,
    selected: true,
    children: [
      {
        id: 16,
        parentId: 6,
        name: "系统消息",
        type: 2,
        selected: true,
      },
      {
        id: 17,
        parentId: 6,
        name: "系统设置",
        type: 2,
        selected: true,
      },
    ],
  },
];
export default {
  name: "menuModal",
  data() {
    return {
      visible: false,
      record: {},
      confirmLoading: false,
      data,
      columns,
      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  methods: {
    async show() {
      console.log(1111);
      this.visible = true;
      // if (record) {
      //   this.record = record;
      //   this.$nextTick(() => {
      //     this.form.setFieldsValue(
      //       pick(record, "menuName", "menuEnName", "type", "parentId")
      //     );
      //   });
      // }
    },
    handleCancel() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.visible = false;
    },
    handleSave() {},
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          this.selectedRows = selectedRows;
        },
        // getCheckboxProps: (record) => ({
        //   props: {
        //     disabled: this.status == 1,
        //     name: record.name,
        //   },
        // }),
      };
    },
  },
};
</script>
<style lang="less" scoped></style>