<!--
 * @Author: zhangjingqing
 * @Date: 2022-05-03 13:25:25
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-28 10:09:10
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\tenantManagement\tenant\FiledsListModal.vue
-->
<template>
  <a-modal
    :title="title"
    :width="700"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleSave"
    @cancel="handleCancel"
  >
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="扩展字段名称">
        <a-select
          ref="select"
          style="width: 100%"
          v-decorator="[
            'expandFieldId',
            {
              rules: [{ required: true, message: '扩展字段名称不能为空' }],
            },
          ]"
          placeholder="请选择扩展字段"
        >
          <a-select-option
            v-for="(item, i) in list"
            :key="i"
            :value="item.id"
            >{{ item.expandFieldName }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="扩展字段值">
        <a-input
          v-decorator="[
            'expandFieldValue',
            { rules: [{ required: true, message: '扩展字段值不能为空' }] },
          ]"
          placeholder="请输入扩展字段值"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import {
  queryExtendFieldServiceList,
  createSystemTenantidExtend,
  updateSystemTenantidExtend,
} from "@/api/tenant";
export default {
  name: "",
  data() {
    return {
      visible: false,
      status: 1,
      confirmLoading: false,
      tenantId: "",
      form: this.$form.createForm(this, { name: "form" }),
      list: [],
      record: {},
    };
  },
  methods: {
    async queryList() {
      this.loading = true;
      let res = await queryExtendFieldServiceList({
        page: 1,
        pageSize: 100,
      });
      if (res.status == 200) {
        this.list = res.data.rows;
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
      this.loading = false;
    },
    async show(tenantId, status, record) {
      this.tenantId = tenantId;
      this.status = status;
      this.visible = true;
      await this.queryList();
      if (status == 2) {
        this.record = record;
        this.form.setFieldsValue(Object.assign({}, record));
      }
    },
    async handleSave() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let item = this.list.filter(
            (item) => item.id == values.expandFieldId
          )[0];
          let params = {
            expandFieldId: item.id,
            expandFieldName: item.expandFieldName,
            expandFieldValue: values.expandFieldValue,
            tenantId: this.tenantId,
          };
          let res;
          if (this.status == 1) {
            res = await createSystemTenantidExtend(params);
          } else {
            res = await updateSystemTenantidExtend(
              Object.assign({}, this.record, params)
            );
          }
          if (res.status == 200) {
            this.$emit("reloadField");
            this.visible = false;
            this.handleCancel();
          } else {
            this.$notification.error({
              message: "系统提示",
              description: res.msg || res.message,
              duration: 4,
            });
          }
        }
      });
    },
    handleCancel() {
      this.record = {};
      this.form.resetFields();
      this.visible = false;
    },
  },
  computed: {
    title() {
      let title;
      switch (this.status) {
        case 1:
          title = "关联扩展字段";
          break;
        case 2:
          title = "编辑";
          break;
      }
      return title;
    },
  },
};
</script>
<style lang="less" scoped>
</style>