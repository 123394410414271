var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":_vm.title,"width":700,"visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleSave,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 16 }}},[_c('a-form-item',{attrs:{"label":"扩展字段名称"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'expandFieldId',
          {
            rules: [{ required: true, message: '扩展字段名称不能为空' }],
          },
        ]),expression:"[\n          'expandFieldId',\n          {\n            rules: [{ required: true, message: '扩展字段名称不能为空' }],\n          },\n        ]"}],ref:"select",staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择扩展字段"}},_vm._l((_vm.list),function(item,i){return _c('a-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.expandFieldName))])}),1)],1),_c('a-form-item',{attrs:{"label":"扩展字段值"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'expandFieldValue',
          { rules: [{ required: true, message: '扩展字段值不能为空' }] },
        ]),expression:"[\n          'expandFieldValue',\n          { rules: [{ required: true, message: '扩展字段值不能为空' }] },\n        ]"}],attrs:{"placeholder":"请输入扩展字段值"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }