<!--
 * @Author: zhangjingqing
 * @Date: 2022-04-09 10:32:53
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-12 11:08:49
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\tenantManagement\tenant\SettingModal.vue
-->
<template>
  <a-modal
    title="租户配置"
    :width="800"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleSave"
    @cancel="handleCancel"
  >
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="电话坐席数量上限">
        <a-input
          type="number"
          v-decorator="[
            'agentSize',
            {
              rules: [{ required: true, message: '电话坐席数量上限不能为空' }],
            },
          ]"
          placeholder="请输入电话坐席数量上限"
        />
      </a-form-item>
      <a-form-item label="文件存储量上限(kb)">
        <a-input
          type="number"
          v-decorator="[
            'fileSize',
            {
              rules: [
                { required: true, message: '文件存储量上限(kb)不能为空' },
              ],
            },
          ]"
          placeholder="请输入文件存储量上限(kb)"
        />
      </a-form-item>
      <a-form-item label="图片存储量上限(kb)">
        <a-input
          type="number"
          v-decorator="[
            'pictureSize',
            {
              rules: [
                { required: true, message: '图片存储量上限(kb)不能为空' },
              ],
            },
          ]"
          placeholder="请输入图片存储量上限(kb)"
        />
      </a-form-item>
      <a-form-item label="用户数量上限">
        <a-input
          type="number"
          v-decorator="[
            'userSize',
            { rules: [{ required: true, message: '用户数量上限不能为空' }] },
          ]"
          placeholder="请输入用户数量上限"
        />
      </a-form-item>
      <a-form-item label="有效结束时间">
        <a-date-picker
          style="width: 100%"
          v-decorator="[
            'validityEndTime',
            { rules: [{ required: true, message: '有效结束时间不能为空' }] },
          ]"
          placeholder="请输入有效结束时间"
        />
      </a-form-item>
      <a-form-item label="有效开始时间">
        <a-date-picker
          style="width: 100%"
          v-decorator="[
            'validityStartTime',
            { rules: [{ required: true, message: '有效开始时间不能为空' }] },
          ]"
          placeholder="请输入有效开始时间"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import pick from "lodash.pick";
import {
  querySystemTenantidCfg,
  createSystemTenantidCfg,
  updateSystemTenantidCfg,
} from "@/api/tenant";
export default {
  name: "settingModal",
  data() {
    return {
      visible: false,
      status: 2,
      id: "",
      record: {},
      form: this.$form.createForm(this, { name: "form" }),
      confirmLoading: false,
    };
  },
  methods: {
    async show(id) {
      this.visible = true;
      if (id) {
        this.id = id;
        let res = await querySystemTenantidCfg({ id: id });
        if (res.status == 200) {
          let data = res.data;
          if (!data) {
            this.status = 2;
          } else {
            this.record = data;
            this.status = 3;
            this.$nextTick(() => {
              this.form.setFieldsValue(
                pick(
                  data,
                  "agentSize",
                  "fileSize",
                  "pictureSize",
                  "userSize",
                  "validityEndTime",
                  "validityStartTime",
                  "usedFileSize",
                  "usedPictureSize",
                  "userUsedSize",
                  "agentUsedSize"
                )
              );
            });
          }
        } else {
          this.$notification.error({
            message: "系统提示",
            description: res.msg || res.message,
            duration: 4,
          });
        }
      }
    },
    handleCancel() {
      this.form.resetFields();
      this.visible = false;
    },
    handleSave() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let res;
          if (this.status == 2) {
            res = await createSystemTenantidCfg(
              Object.assign(values, {
                tenantId: this.id,
                usedFileSize: 10,
                usedPictureSize: 10,
                userUsedSize: 10,
                agentUsedSize: 10,
              })
            );
          } else {
            res = await updateSystemTenantidCfg(
              Object.assign(this.record, values, {
                usedFileSize: 10,
                usedPictureSize: 10,
                userUsedSize: 10,
                agentUsedSize: 10,
              })
            );
          }
          if (res.status == 200) {
            this.$message.success("配置成功！");
            this.visible = false;
            this.form.resetFields();
            this.$emit("reload");
          } else {
            this.$notification.error({
              message: "系统提示",
              description: res.msg || res.message,
              duration: 4,
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped></style>