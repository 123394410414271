<!--
 * @Author: zhangjingqing
 * @Date: 2022-04-09 10:31:24
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-30 15:50:07
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\tenantManagement\tenant\TenantModal.vue
-->
<template>
  <a-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleSave"
    @cancel="handleCancel"
  >
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
      <!-- <a-form-item label="企业logo">
        <a-upload
          name="avatar"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          :before-upload="beforeUpload"
          @change="handleChange"
          v-decorator="[
            'menuName',
            { rules: [{ required: true, message: '联系人不能为空' }] },
          ]"
        >
          <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
          <div v-else>
            <loading-outlined v-if="loading"></loading-outlined>
            <plus-outlined v-else></plus-outlined>
            <div class="ant-upload-text">Upload</div>
          </div>
        </a-upload>
      </a-form-item> -->
      <!-- <a-form-item label="uuid">
        <a-input
          v-decorator="[
            'uuid',
            { rules: [{ required: true, message: 'uuid不能为空' }] },
          ]"
          placeholder="请输入uuid"
        />
      </a-form-item> -->
      <a-form-item label="租户名称">
        <a-input
          v-decorator="[
            'name',
            { rules: [{ required: true, message: '租户名称不能为空' }] },
          ]"
          placeholder="请输入租户名称"
        />
      </a-form-item>
      <a-form-item label="联系邮箱">
        <a-input
          v-decorator="[
            'contactEmail',
            { rules: [{ required: true, message: '联系邮箱不能为空' }] },
          ]"
          placeholder="请输入联系邮箱"
        />
      </a-form-item>
      <a-form-item label="管理员密码" v-if="status == 2">
        <a-input
          v-decorator="[
            'password',
            { rules: [{ required: true, message: '管理员密码不能为空' }] },
          ]"
          placeholder="请输入管理员密码"
        />
      </a-form-item>
      <a-form-item label="联系电话">
        <a-input
          v-decorator="[
            'contactMobile',
            { rules: [{ required: true, message: '联系电话不能为空' }] },
          ]"
          placeholder="请输入联系电话"
        />
      </a-form-item>
      <a-form-item label="联系人">
        <a-input
          v-decorator="[
            'contactPerson',
            { rules: [{ required: true, message: '联系人不能为空' }] },
          ]"
          placeholder="请输入联系人"
        />
      </a-form-item>
      <a-form-item label="系统名称">
        <a-input
          v-decorator="[
            'systemName',
            { rules: [{ required: true, message: '系统名称不能为空' }] },
          ]"
          placeholder="请输入系统名称"
        />
      </a-form-item>
      <!-- <a-form-item label="首页icon">
        <a-upload
          name="avatar"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          :before-upload="beforeUpload"
          @change="handleChange"
          v-decorator="[
            'menuName',
            { rules: [{ required: true, message: '联系人不能为空' }] },
          ]"
        >
          <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
          <div v-else>
            <loading-outlined v-if="loading"></loading-outlined>
            <plus-outlined v-else></plus-outlined>
            <div class="ant-upload-text">Upload</div>
          </div>
        </a-upload>
      </a-form-item> -->
    </a-form>
  </a-modal>
</template>
<script>
import pick from "lodash.pick";
import { createSystemTenantid, updateSystemTenantid } from "@/api/tenant";
export default {
  name: "tenantModal",
  data() {
    return {
      status: 1,
      visible: false,
      record: {},
      form: this.$form.createForm(this, { name: "form" }),
      confirmLoading: false,
    };
  },
  methods: {
    async show(status, record) {
      this.visible = true;
      this.status = status;
      if (record.id) {
        this.record = record;
        this.$nextTick(() => {
          this.form.setFieldsValue(
            pick(
              record,
              "name",
              "contactEmail",
              "contactMobile",
              "contactPerson",
              "systemName"
            )
          );
        });
      }
    },
    handleCancel() {
      this.form.resetFields();
      this.visible = false;
    },
    handleSave() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let res;
          if (this.status == 2) {
            res = await createSystemTenantid(
              Object.assign(values, { icon: "aaa.jpg", logo: "bbb.jpg" })
            );
          } else {
            res = await updateSystemTenantid(
              Object.assign(this.record, values)
            );
          }
          if (res.status == 200) {
            this.$message.success(
              this.status == 2 ? "新增成功！" : "修改成功！"
            );
            this.handleCancel();
            this.$emit("reload");
          } else {
            this.$notification.error({
              message: "系统提示",
              description: res.msg || res.message,
              duration: 4,
            });
          }
        }
      });
    },
  },
  computed: {
    title() {
      let title;
      switch (this.status) {
        case 1:
          title = "查看";
          break;
        case 2:
          title = "新增租户";
          break;
        case 3:
          title = "编辑租户";
          break;
      }
      return title;
    },
  },
};
</script>
<style lang="less" scoped></style>
